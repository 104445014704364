import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { HelpPanel } from './HelpPanel';
import { DataPanel } from './DataPanel';
import { BiHelpCircle } from 'react-icons/bi';
import { AnimatePresence, motion } from 'framer-motion';

import '../styles/output.css';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [view, setView] = useState<'DataPanel' | 'Help'>('DataPanel');

  useEffect(() => {
    if (data.series.length === 0) {
      setView('Help');
    }
  }, [data]);

  const toggleView = () => {
    if (view === 'Help') {
      setView('DataPanel');
    } else if (view === 'DataPanel') {
      setView('Help');
    }
  };

  return (
    <div
      className={cx(
        css`
          width: ${width}px;
          height: ${height}px;
          overflow-y: scroll;
        `
      )}
    >
      <div className="flex w-100">
        <div className="flex-1">
          <div className="w-10  ml-auto">
            <button aria-label="Toggle view" className="ml-1 justify-end " type="submit">
              <BiHelpCircle className="" size="1.5rem" onClick={() => toggleView()} />
            </button>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div key={view} animate={view}>
          {view === 'Help' && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <HelpPanel data={data} />
            </motion.div>
          )}
          {view === 'DataPanel' && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <DataPanel data={data} height={height} width={width} />
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
