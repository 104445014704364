//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { DataFrame } from '@grafana/data';
import { CodeBlock } from '../CodeBlock';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { css, cx } from '@emotion/css';
import * as d3 from 'd3';

/**
 * TreeNodes
 */
const GenericText = ({ dataFrame }: { dataFrame: DataFrame }) => {
  const [lastValue, setLastValue] = useState('');
  const [label, setLabel] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const length = dataFrame.length;
    setLastValue(dataFrame.fields[1].values[length - 1]);
    setLabel(dataFrame.fields[1].name);

    setIsLoading(false);
  }, [dataFrame]);

  //   if (dataToUse?.time) {
  //     return null;
  //   }
  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <div className="flex text-white">
      <div>{label}</div>
      <div className="ml-3">{lastValue}</div>
    </div>
  );
};

export default GenericText;
