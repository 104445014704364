//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { PanelProps, PanelData, Vector } from '@grafana/data';
import { CodeBlock } from '../CodeBlock';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { css, cx } from '@emotion/css';
import * as d3 from 'd3';

type FormattedData = {
  time: Vector;
  values: Vector;
  length: 15;
};

function BarGraph({ data, width, height }: { data: FormattedData; width: number }) {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const margin = {
    top: 10,
    right: 10,
    bottom: 20,
    left: 24,
  };

  // Scale for x-axis
  const xScale = d3
    .scaleTime()
    .domain([data.time[0], data.time[data.length - 1]])
    .range([0, width]);

  // Ensure there is not space between the bars
  const barWidth = width / (data.length - 1) + 1;

  return (
    <>
      <svg width={width} height={height}>
        {[...Array(data.length)].map((x, i) => {
          const time = data.time[i];
          const value = data.values[i];
          return (
            <rect
              key={i}
              x={xScale(time)}
              y={0}
              width={barWidth}
              height={height}
              fill={value === 1 ? 'green' : 'red'}
              onMouseOver={() => setHoveredIndex(i)}
              onMouseOut={() => setHoveredIndex(-1)}
              title={time}
            />
          );
        })}
      </svg>
      <div className="h-16">
        <svg className="mt-4">
          <g>
            <g>
              <rect x={0} y={0} width={18} height={18} fill="Red" />
              <text x={25} y={14} fill="white">
                Not Running
              </text>
            </g>
            <g>
              <rect x={0} y={20} width={18} height={18} fill="Green" />
              <text fill="white" x={25} y={20 + 14}>
                Running
              </text>
            </g>
          </g>
        </svg>
      </div>
      {/* {hoveredIndex !== -1 && (
        <text className="text-white" x={10} y={20}>
          {hoveredIndex}
        </text>
      )} */}
    </>
  );
}

/**
 * TreeNodes
 */
const DiscreteBarGraph = ({ data, width, height }: { data: PanelData; width: number; height: number }) => {
  const [dataToUse, setDataToUse] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const graphData = {
      time: data.series[0].fields[0].values.buffer,
      values: data.series[0].fields[1].values.buffer,
      length: data.series[0].length,
    };
    console.log({ graphData });
    console.log('ddd', data.series[0]);

    setDataToUse(graphData);
    setIsLoading(false);
  }, [data]);

  //   if (dataToUse?.time) {
  //     return null;
  //   }
  if (isLoading) {
    return <>Loading</>;
  }

  //   return <CodeBlock json={dataToUse?.time[0]} />;

  return <BarGraph data={dataToUse} width={width} height={height} />;
};

export default DiscreteBarGraph;
