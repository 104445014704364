import React, { useState } from 'react';

import { CodeBlock } from '../CodeBlock';
import { AiFillCheckCircle } from 'react-icons/ai';
import { PanelData } from '@grafana/data';
import { Switch } from '@headlessui/react';
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const queryA = `
SELECT
    $__time(data.ts),
    CAST (data.value_string AS DECIMAL) as "Ht_Pump_Comp Start"
FROM data, topics
WHERE
    $__timeFilter(ts) AND
    data.topic_id = topics.topic_id AND
    -- CHANGE TOPIC NAME BELOW
    topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start')
ORDER BY 1,2`;

const queryABool = `
SELECT
    $__time(data.ts),
    CASE WHEN data.value_string LIKE 'true' THEN 1 ELSE 0 END as "Ht_Pump_Comp Start"
FROM data, topics
WHERE
    $__timeFilter(ts) AND
    data.topic_id = topics.topic_id AND
    -- CHANGE TOPIC NAME BELOW
    topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start')
ORDER BY 1,2`;

const queryB = `
SELECT 
    NOW()  as "time",
    COUNT (sub.ts) as "Ht_Pump_Comp Number of Starts"
FROM (
    SELECT
        data.ts,
        -- lag(data.ts) OVER (ORDER BY data.ts ) as previous_ts,
        data.value_string,
        lag(data.value_string) OVER (ORDER BY data.ts ) as previous_value_string
    FROM data, topics
    WHERE
        $__timeFilter(ts) AND
        data.topic_id = topics.topic_id AND
        -- CHANGE TOPIC NAME BELOW
        topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start')
        ORDER BY data.ts DESC       
) sub
WHERE CAST (sub.value_string AS DECIMAL) = 1 AND CAST (sub.previous_value_string AS DECIMAL)  = 0`;

const queryBBool = `
SELECT 
    NOW()  as "time",
    COUNT (sub.ts) as "Ht_Pump_Comp Number of Starts"
FROM (
    SELECT
        data.ts,
        -- lag(data.ts) OVER (ORDER BY data.ts ) as previous_ts,
        data.value_string,
        lag(data.value_string) OVER (ORDER BY data.ts ) as previous_value_string
    FROM data, topics
    WHERE
        $__timeFilter(ts) AND
        data.topic_id = topics.topic_id AND
        -- CHANGE TOPIC NAME BELOW
        topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start')
        ORDER BY data.ts DESC       
) sub
WHERE  (sub.value_string) LIKE 'true' AND sub.previous_value_string LIKE 'false'`;

const queryC = `
SELECT
    NOW() as time,
    SUM(CAST (data.value_string AS DECIMAL)) as "Ht_Pump_Comp Rt"
FROM data, topics
WHERE
    $__timeFilter(ts) AND
    data.topic_id = topics.topic_id AND
    -- CHANGE TOPIC NAME BELOW
    topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start');`;

const queryCBool = `
SELECT
    NOW() as time,
    SUM(CASE WHEN data.value_string LIKE 'true' THEN 1 ELSE 0 END ) as "Ht_Pump_Comp Rt"
FROM data, topics
WHERE
    $__timeFilter(ts) AND
    data.topic_id = topics.topic_id AND
    -- CHANGE TOPIC NAME BELOW
    topics.topic_name LIKE ('lac/50000/Ht_Pump_Comp Start');`;

const getDescription = ({ queryIndex }: { queryIndex: string }) => {
  return `In '${queryIndex}' add the a query like below (changing the topic name)`;
};

const initialSteps = [
  {
    step: 0,
    name: 'Current State of the Pump',
    description: getDescription({ queryIndex: 'A' }),
    query: queryA,
    queryBool: queryABool,
  },
  {
    step: 1,
    name: 'Number of starts of the Pump',
    description: getDescription({ queryIndex: 'B' }),
    query: queryB,
    queryBool: queryBBool,
  },
  {
    step: 2,
    name: 'Runtime of the Pump',
    description: getDescription({ queryIndex: 'C' }),
    query: queryC,
    queryBool: queryCBool,
  },
];

type StepProps = {
  step: number;
  name: string;
  description: string;
  query: string;
  selected: boolean;
  status: 'CURRENT' | 'COMPLETED' | 'UPCOMING';
  queryBool: string;
};

const getSteps = ({ seriesLength, selectedIndex }: { seriesLength: number; selectedIndex: number }): StepProps[] => {
  return initialSteps.map((step) => {
    return {
      ...step,
      status: step.step === seriesLength ? 'CURRENT' : step.step < seriesLength ? 'COMPLETED' : 'UPCOMING',
      selected: step.step === selectedIndex,
    };
  });
};

/**
 * EmptyPanel
 */
const HelpPanel = ({ data }: { data: PanelData }) => {
  const [showBool, setShowBool] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(data?.series?.length);
  const steps = getSteps({ seriesLength: data?.series?.length, selectedIndex });
  return (
    <>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative pb-10">
              {step.status !== 'COMPLETED' && stepIdx !== steps.length - 1 && (
                <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
              )}
              <a onClick={() => setSelectedIndex(step.step)} className="group relative flex items-start cursor-pointer">
                {step.status === 'COMPLETED' ? (
                  <>
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-700 group-hover:bg-indigo-900">
                        <AiFillCheckCircle className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                      {step.selected && <CodeBlock code={showBool ? step.queryBool : step.query} />}
                    </span>
                  </>
                ) : step.status === 'CURRENT' ? (
                  <>
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-200 bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-indigo-200" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className={`${step.selected ? 'font-bold' : 'font-medium'} text-sm  text-indigo-100 `}>
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                      {step.selected && <CodeBlock code={showBool ? step.queryBool : step.query} />}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                      {step.selected && <CodeBlock code={showBool ? step.queryBool : step.query} />}
                    </span>
                  </>
                )}
              </a>
            </li>
          ))}
        </ol>
      </nav>
      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={showBool}
          onChange={setShowBool}
          className={classNames(
            showBool ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              showBool ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-100">
            Is the Topic value stored {showBool ? <>&quot;true/false&quot;</> : <>&quot;1/0&quot;</>}
          </span>
        </Switch.Label>
      </Switch.Group>
    </>
  );
};

export default HelpPanel;
