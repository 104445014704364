//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { PanelProps, PanelData, Vector } from '@grafana/data';
import { CodeBlock } from '../CodeBlock';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { css, cx } from '@emotion/css';
import * as d3 from 'd3';

type FormattedData = {
  time: Vector;
  values: Vector;
  length: 15;
};

const SvgSample = ({ spin }: { spin: boolean }) => {
  return (
    <div className="flex">
      <svg
        className={`${spin ? 'animate-spin' : ''} -ml-1 mr-3 h-5 w-5  text-blue-100`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <div className="text-white">{spin ? 'Running' : 'Not Running'}</div>
    </div>
  );
};

/**
 * TreeNodes
 */
const CurrentPumpStatus = ({ data, width, height }: { data: PanelData; width: number; height: number }) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const length = data.series[0].length;
    const lastValue = data.series[0].fields[1].values[length - 1];
    setIsRunning(lastValue === 1);
    setIsLoading(false);
  }, [data]);

  //   if (dataToUse?.time) {
  //     return null;
  //   }
  if (isLoading) {
    return <>Loading</>;
  }

  //   return <CodeBlock json={dataToUse?.time[0]} />;

  return <SvgSample spin={isRunning} />;
};

export default CurrentPumpStatus;
