//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { PanelProps, PanelData, Vector } from '@grafana/data';
import { CodeBlock } from '../CodeBlock';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { css, cx } from '@emotion/css';
import * as d3 from 'd3';

import { CurrentPumpStatus } from '../CurrentPumpStatus';
import { GenericText } from '../GenericText';
import { DiscreteBarGraph } from '../DiscreteBarGraph';

/**
 * TreeNodes
 */
const DataPanel = ({ data, width, height }: { data: PanelData; width: number; height: number }) => {
  //   return <CodeBlock json={dataToUse?.time[0]} />;

  return (
    <>
      <DiscreteBarGraph data={data} width={width} height={height - 160} />
      <CurrentPumpStatus data={data} />
      <GenericText dataFrame={data.series[1]} />
      <GenericText dataFrame={data.series[2]} />
    </>
  );
};

export default DataPanel;
